.Jigsaw-piece-list {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Piece-list-piece {
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
