.Droppable-piece {
  width: 100%;
  height: 100%;
}
.Droppable-piece-canvas {
  width: 100%;
  height: 100%;
}
.Droppable-piece-hide {
  opacity: 0;
}
